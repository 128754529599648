import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { AnalyticsBrowser } from '@segment/analytics-next';

const analytics = AnalyticsBrowser.load({ writeKey: 'r5ssv1SoTuGaQqK8tez2nvcTaVk2OyYe' });

analytics.page();

export { analytics };

const initializeChatbot = (containerId: string) => {
  const container = document.getElementById(containerId);
  if (container && !container.hasChildNodes()) {
    const root = ReactDOM.createRoot(container);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } else if (container && (window as any).chatbotReattach) {
    (window as any).chatbotReattach(containerId);
  }
  return {
    reattach: (newContainerId: string) => {
      if ((window as any).chatbotReattach) {
        (window as any).chatbotReattach(newContainerId);
      }
    }
  };
};

const rootContainer = document.getElementById('root');
if (rootContainer) {
  const root = ReactDOM.createRoot(rootContainer);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

const globalWindow = window as any;
globalWindow.ChatbotWidget = initializeChatbot;

export default initializeChatbot;
