import React, { useState, FC, useEffect, useRef } from 'react';
import './Chatbot.css';
import MessageList from './MessageList';
import Options from './Options';
import Dropdown from './Dropdown';
import ChatInput from './ChatInput';
import FeedbackForm from './FeedbackForm';
import { Message, ApiResponse } from './types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { trackChatbotStarted, trackMessageSent, trackFeedbackSent, trackChatbotClosed, trackChatbotTimeSpent } from '../../utils/segmentTracking';
import { v4 as uuidv4 } from 'uuid'; 
import ReactDOM from 'react-dom';

const isProd = process.env.NODE_ENV === 'production';
const logo = isProd ? 'https://cdn.prod.website-files.com/65cb6a0f3f7384e3bd5ed3ec/66ec30f29dc96e4318ff8afc_full_logo_white_nonid.svg' : require('../../assets/full_logo_white_nonid.svg').default;
const feedbackLogo = isProd ? 'https://cdn.prod.website-files.com/65cb6a0f3f7384e3bd5ed3ec/66ec30f2653ce1c2ff685ca0_thumbsup.svg' : require('../../assets/thumbsup.svg').default;
const arrowClockWise = isProd ? 'https://cdn.prod.website-files.com/65cb6a0f3f7384e3bd5ed3ec/66ec30f2ad1d638cfa2f4170_arrow_clockwise.svg' : require('../../assets/arrow_clockwise.svg').default;
const botAvatar = isProd ? 'https://cdn.prod.website-files.com/65cb6a0f3f7384e3bd5ed3ec/66e9cee3bca8c5fcc601b119_bot_avatar.svg' : require('../../assets/bot_avatar.svg').default;

const Chatbot: FC = () => {
  const [messages, setMessages] = useState<Message[]>(() => {
    const savedMessages = localStorage.getItem('chatbotMessages');
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [input, setInput] = useState<string>('');
  const [responseType, setResponseType] = useState<string>('menu');
  const [options, setOptions] = useState<string[]>([]);
  const [stage, setStage] = useState<string>(() => {
    return localStorage.getItem('chatbotStage') || 'greeting';
  });
  const [isOpen, setIsOpen] = useState<boolean>(() => {
    return localStorage.getItem('chatbotIsOpen') === 'true';
  });
  const [selectedCountries, setSelectedCountries] = useState<any[]>([]);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(false); 
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [userName, setUserName] = useState<string>(() => {
    return localStorage.getItem('chatbotUserName') || '';
  });
  const [userEmail, setUserEmail] = useState<string>(() => {
    return localStorage.getItem('chatbotUserEmail') || '';
  });
  const [companyName, setCompanyName] = useState<string>(() => {
    return localStorage.getItem('chatbotCompanyName') || '';
  });
  const [sessionId, setSessionId] = useState<string | null>(() => {
    return localStorage.getItem('chatbotSessionId');
  });
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isThinking, setIsThinking] = useState<boolean>(false);
  const [showIntro, setShowIntro] = useState<boolean>(() => {
    const hasSeenIntro = localStorage.getItem('hasSeenIntro');
    return hasSeenIntro !== 'true';
  });
  const [hovered, setHovered] = useState<boolean>(false); 
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  const [userId, setUserId] = useState<string>(() => {
    const storedUserId = localStorage.getItem('user_id');
    if (storedUserId) {
      return storedUserId;
    } else {
      const newUserId = uuidv4();
      localStorage.setItem('user_id', newUserId);
      return newUserId;
    }
  });

  // Track when the chatbot is opened and start the timer
  useEffect(() => {
    if (isOpen && sessionId && !startTime) {
      trackChatbotStarted(sessionId, userId);
      setStartTime(new Date());
    }
  }, [isOpen, sessionId, startTime, userId]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCloseIntro = () => {
    setShowIntro(false);
    localStorage.setItem('hasSeenIntro', 'true');
  };

  const initMessage = async (newSession: boolean = false) => {
    const session_id = newSession ? null : sessionId;
  
    const response = await fetch(`${backendUrl}/ask_compliance_rules`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ message: '', stage: 'greeting', session_id: session_id }),
    });

    if (!response.ok) {
      console.error('Error in response:', response.statusText, await response.text());
      return;
    }

    const data: ApiResponse = await response.json();
    
    setSessionId(data.session_id);
    localStorage.setItem('chatbotSessionId', data.session_id);
    
    setMessages([{ sender: 'bot', text: data.response, timestamp: new Date().toLocaleTimeString() }]);
    localStorage.setItem('chatbotMessages', JSON.stringify([{ sender: 'bot', text: data.response, timestamp: new Date().toLocaleTimeString() }]));
    
    setResponseType(data.type);
    setOptions(data.options || []);
    
    setStage(data.next_stage || 'greeting');
    localStorage.setItem('chatbotStage', data.next_stage || 'greeting');
    
    setShowInput(data.text || false);
    setUserName(''); 
    setUserEmail(''); 
    setCompanyName('');
  };

  useEffect(() => {
    initMessage();
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const validateEmail = (email: string): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const personalEmailDomains = [
      "gmail", "yahoo", "hotmail", "aol", "outlook",
      "live", "icloud", "msn"
    ];
    const domain = email.split('@').pop()?.split('.').shift();
    if (!domain) {
      toast.error("Please enter a valid email address.");
      return false;
    }
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }
    if (personalEmailDomains.includes(domain)) {
      toast.error("Please enter a professional email address.");
      return false;
    }
    return true;
  };

  const sendMessage = async (inputMessage: string, currentStage: string) => {
    if (inputMessage.trim() === '') return;

    if (currentStage === 'get_user_email') {
      if (!validateEmail(inputMessage)) {
        return;
      }
    }

    setEmailError(null);

    const newMessages: Message[] = [...messages, { sender: 'user', text: inputMessage, timestamp: new Date().toLocaleTimeString() }];
    setMessages(newMessages);
    setInput(''); 
    setIsThinking(true);

    let endpoint = `${backendUrl}/ask_compliance_rules`;
    let body = JSON.stringify({ message: inputMessage, stage: currentStage, session_id: sessionId });

    if (currentStage === 'learn_nexeraid' || currentStage === 'llm_conversation_nexeraid') {
      endpoint = `${backendUrl}/ask_kg_rag`;
      body = JSON.stringify({ message: inputMessage, session_id: sessionId });
    } else if (currentStage === 'explore_regulations' || currentStage === 'llm_conversation_regulations') {
      endpoint = `${backendUrl}/ask_llm_regulations`;
      body = JSON.stringify({ message: inputMessage, session_id: sessionId });
    } else if (currentStage === 'learn_nexeraid_llm' || currentStage === 'llm_conversation_nexeraid_llm') {
      endpoint = `${backendUrl}/ask_llm_nexeraid`;
      body = JSON.stringify({ message: inputMessage, session_id: sessionId });
    }

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: body,
    });

    const data: ApiResponse = await response.json();
    const llmOutput = data.response; // Store the LLM response (bot message)

    setMessages(prevMessages => [
      ...prevMessages,
      { sender: 'bot', text: data.response, timestamp: new Date().toLocaleTimeString() }
    ]);
    setSessionId(data.session_id);
    setResponseType(data.type || 'menu');
    setOptions(data.options || []);
    setStage(data.next_stage || currentStage);
    setShowInput(currentStage === 'llm_greeting' || currentStage === 'learn_nexeraid_llm' || currentStage === 'llm_conversation_nexeraid_llm' || currentStage === 'learn_nexeraid' || currentStage === 'llm_conversation_nexeraid' || currentStage === 'llm_conversation_regulations' || currentStage === 'explore_regulations' || data.text);
    setInput('');
    setIsThinking(false);

    // Track the message sent along with the LLM response
    trackMessageSent(inputMessage, llmOutput, sessionId || '', userId);

    if (currentStage === 'get_user_name') {
      setUserName(inputMessage);
    } else if (currentStage === 'get_user_email') {
      setUserEmail(inputMessage);
    } else if (currentStage === 'company_name') {
      setCompanyName(inputMessage);
    }

    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleOptionClick = (option: string) => {
    sendMessage(option, stage);
    setShowIntro(false);
    localStorage.setItem('hasSeenIntro', 'true');
    setIsOpen(true);
  };

  const handleDropdownChange = (selectedOption: any) => {
    if (stage === 'operating_countries') {
      setSelectedCountries(selectedOption);
    } else {
      sendMessage(selectedOption.value, stage);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (stage === 'operating_countries' && selectedCountries.length > 0) {
        handleConfirmSelection();
      } else {
        sendMessage(input, stage);
      }
    }
  };

  const handleConfirmSelection = () => {
    if (selectedCountries.length > 0) {
      const selectedValues = selectedCountries.map(option => option.value).join(', ');
      sendMessage(selectedValues, stage);
      setSelectedCountries([]);
    }
  };

  const toggleChatbot = () => {
    if (isOpen && sessionId && startTime) {
      const endTime = new Date();
      const timeSpent = (endTime.getTime() - startTime.getTime()) / 1000; // Time in seconds

      trackChatbotTimeSpent(timeSpent, sessionId, userId); // Pass userId
      trackChatbotClosed(sessionId, userId); // Track the chatbot being closed
    }
    setIsOpen(!isOpen);
    setShowIntro(false);
    setStartTime(null); // Reset start time when chatbot closes
  };

  const handleRefresh = async () => {
    setUserName('');
    setUserEmail('');
    setCompanyName('');
    setEmailError(null);
    await initMessage(true);
  };


  const toggleFeedbackForm = () => {
    setShowFeedbackForm(!showFeedbackForm);

    if (!showFeedbackForm && sessionId) {
      trackFeedbackSent('Feedback form opened', sessionId, userId);
  }
  };

  const reattach = (containerId: string) => {
    const container = document.getElementById(containerId);
    if (container && !container.hasChildNodes()) {
      ReactDOM.render(<Chatbot />, container);
    }
  };

  // Exposer la méthode reattach
  if (typeof window !== 'undefined') {
    (window as any).chatbotReattach = reattach;
  }

  return (
    <>
      {showIntro && (
        <div
          className="intro-message"
          onMouseEnter={() => setHovered(true)} 
          onMouseLeave={() => setHovered(false)}
        >
          <div className="intro-content">
            <img src={botAvatar} alt="ComPilot" />
            <div>
              👋 <strong>Welcome to ComPilot!</strong><br/><br/>
              Hi, I'm your virtual <strong>Compliance Assistant</strong>, here to help web3 companies like yours navigate the complex world of compliance.<br /><br/>
              <strong>How can I help you?</strong>
            </div>
            {(hovered || isMobile) && (
              <button className="close-button" onClick={handleCloseIntro}>
                x
              </button>
            )}
          </div>
          <div className="intro-options">
            <button onClick={() => handleOptionClick('1️⃣ Discover my compliance requirements')}>1️⃣ Discover my compliance requirements</button>
            <button onClick={() => handleOptionClick('2️⃣ Learn more about ComPilot')}>2️⃣ Learn more about ComPilot</button>
            <button onClick={() => handleOptionClick('3️⃣ Explore current regulations')}>3️⃣ Explore current regulations</button>
          </div>
        </div>
      )}
      <button className="chat-toggle" onClick={toggleChatbot}>
        {isOpen ? '×' : '☰'}
      </button>
      <div className={`chatbot-widget ${isOpen ? 'open' : ''}`}>
        <header>
          <div className="header-content">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="Company Logo" className="company-logo" />
            </div>
            <div className="header-buttons">
              <div className="refresh-icon" onClick={handleRefresh}>
                <img src={arrowClockWise} alt="Refresh" className="refresh-logo" />
              </div>
              <div className="feedback-icon" onClick={toggleFeedbackForm}>
                <img src={feedbackLogo} alt="Feedback" className="feedback-logo" />
              </div>
            </div>
          </div>
        </header>
        <div className="chat-window">
          <MessageList messages={messages} messagesEndRef={messagesEndRef} />
          {isThinking && <div className="thinking-indicator">ComPilot is thinking...</div>}
        </div>
        {responseType === 'menu' && <Options options={options} handleOptionClick={handleOptionClick} />}
        {responseType === 'dropdown' && (stage === 'company_location' || stage === 'operating_countries') && (
          <Dropdown
            stage={stage}
            handleDropdownChange={handleDropdownChange}
            handleConfirmSelection={handleConfirmSelection}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
          />
        )}
        {showInput && (
          <ChatInput
            input={input}
            setInput={setInput}
            handleKeyDown={handleKeyDown}
            sendMessage={sendMessage}
            stage={stage}
          />
        )}
        {(responseType === 'dropdown' && stage !== 'company_location' && stage !== 'operating_countries') && (
          <div className="options">
            {options.map((option, index) => (
              <button key={index} onClick={() => handleOptionClick(option)}>
                {option}
              </button>
            ))}
          </div>
        )}
        <ToastContainer />
        {/* Only render the FeedbackForm if sessionId is not null */}
        {showFeedbackForm && sessionId && (
          <FeedbackForm closeForm={toggleFeedbackForm} sessionId={sessionId} userId={userId} />
        )}
      </div>
    </>
  );  
};

export default Chatbot;