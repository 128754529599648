const countryOptions = [
  { value: 'United States', label: 'United States', flag: '🇺🇸' },
  { value: 'Canada', label: 'Canada', flag: '🇨🇦' },
  { value: 'United Kingdom', label: 'United Kingdom', flag: '🇬🇧' },
  { value: 'Australia', label: 'Australia', flag: '🇦🇺' },
  { value: 'France', label: 'France', flag: '🇫🇷' },
  { value: 'Germany', label: 'Germany', flag: '🇩🇪' },
  { value: 'Spain', label: 'Spain', flag: '🇪🇸' },
  { value: 'Italy', label: 'Italy', flag: '🇮🇹' },
  { value: 'Austria', label: 'Austria', flag: '🇦🇹' },
  { value: 'Belgium', label: 'Belgium', flag: '🇧🇪' },
  { value: 'Bulgaria', label: 'Bulgaria', flag: '🇧🇬' },
  { value: 'Croatia', label: 'Croatia', flag: '🇭🇷' },
  { value: 'Denmark', label: 'Denmark', flag: '🇩🇰' },
  { value: 'Greece', label: 'Greece', flag: '🇬🇷' },
  { value: 'Hungary', label: 'Hungary', flag: '🇭🇺' },
  { value: 'Iceland', label: 'Iceland', flag: '🇮🇸' },
  { value: 'Ireland', label: 'Ireland', flag: '🇮🇪' },
  { value: 'Latvia', label: 'Latvia', flag: '🇱🇻' },
  { value: 'Lithuania', label: 'Lithuania', flag: '🇱🇹' },
  { value: 'Luxembourg', label: 'Luxembourg', flag: '🇱🇺' },
  { value: 'Malta', label: 'Malta', flag: '🇲🇹' },
  { value: 'Netherlands', label: 'Netherlands', flag: '🇳🇱' },
  { value: 'Norway', label: 'Norway', flag: '🇳🇴' },
  { value: 'Poland', label: 'Poland', flag: '🇵🇱' },
  { value: 'Portugal', label: 'Portugal', flag: '🇵🇹' },
  { value: 'Romania', label: 'Romania', flag: '🇷🇴' },
  { value: 'Slovakia', label: 'Slovakia', flag: '🇸🇰' },
  { value: 'Slovenia', label: 'Slovenia', flag: '🇸🇮' },
  { value: 'Sweden', label: 'Sweden', flag: '🇸🇪' },
  { value: 'Switzerland', label: 'Switzerland', flag: '🇨🇭' },
  { value: 'Brazil', label: 'Brazil', flag: '🇧🇷' },
  { value: 'India', label: 'India', flag: '🇮🇳' },
  { value: 'China', label: 'China', flag: '🇨🇳' },
  { value: 'Japan', label: 'Japan', flag: '🇯🇵' },
  { value: 'South Korea', label: 'South Korea', flag: '🇰🇷' },
  { value: 'Mexico', label: 'Mexico', flag: '🇲🇽' },
  { value: 'Russia', label: 'Russia', flag: '🇷🇺' },
  { value: 'South Africa', label: 'South Africa', flag: '🇿🇦' },
  { value: 'Nigeria', label: 'Nigeria', flag: '🇳🇬' },
  { value: 'Egypt', label: 'Egypt', flag: '🇪🇬' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia', flag: '🇸🇦' },
  { value: 'United Arab Emirates', label: 'United Arab Emirates', flag: '🇦🇪' },
  { value: 'Israel', label: 'Israel', flag: '🇮🇱' }
];

export default countryOptions;
