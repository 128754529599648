import React, { FC } from 'react';
import Select from 'react-select';
import countryOptions from '../countryOptions';

const EmptyClearIndicator = () => null;

interface DropdownProps {
  stage: string;
  handleDropdownChange: (selectedOption: any) => void;
  handleConfirmSelection: () => void;
  selectedCountries: any[];
  setSelectedCountries: React.Dispatch<React.SetStateAction<any[]>>;
}

const Dropdown: FC<DropdownProps> = ({
  stage,
  handleDropdownChange,
  handleConfirmSelection,
  selectedCountries,
  setSelectedCountries,
}) => (
  <div style={{ padding: '10px' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <Select
          options={countryOptions}
          isMulti={stage === 'operating_countries'}
          onChange={handleDropdownChange}
          value={selectedCountries}
          placeholder={stage === 'company_location' ? 'Select a country' : 'Select countries'}
          formatOptionLabel={(option: any) => (
            <div>
              <span role="img" aria-label={option.label}>{option.flag}</span> {option.label}
            </div>
          )}
          closeMenuOnSelect={false}
          menuPlacement="top"
          classNamePrefix="react-select"
          components={{ ClearIndicator: EmptyClearIndicator }}
          styles={{
            control: (base) => ({
              ...base,
              padding: '2px 10px',
              border: '1px solid #ccc',
              borderRadius: '20px',
              boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
              fontSize: '14px',
              minHeight: '42px',
              width: '100%',
            }),
            menu: (base) => ({
              ...base,
            }),
            option: (base) => ({
              ...base,
              display: 'flex',
              alignItems: 'center',
            }),
            multiValue: (base) => ({
              ...base,
              backgroundColor: '#0258FD',
              borderRadius: '10px',
              padding: '5px',
              display: 'inline-flex',
              margin: '2px',
            }),
            multiValueLabel: (base) => ({
              ...base,
              color: '#fff',
            }),
            multiValueRemove: (base) => ({
              ...base,
              color: '#fff',
              ':hover': {
                backgroundColor: '#15245C',
                color: '#fff',
              },
            }),
            valueContainer: (base) => ({
              ...base,
              display: 'flex',
              flexWrap: 'wrap',
              maxHeight: '100px', 
              overflowY: 'auto',
            }),
          }}
        />
      </div>
      {stage === 'operating_countries' && (
        <button onClick={handleConfirmSelection} className="submit-button">
          <svg viewBox="0 0 24 24">
            <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm1 14.5v-3H7v-2h6V7l5 4.5-5 5z"/>
          </svg>
        </button>
      )}
    </div>
  </div>
);

export default Dropdown;
