import React, { useState, FC } from 'react';
import { toast } from 'react-toastify';
import './Chatbot.css';
import { trackFeedbackSent } from '../../utils/segmentTracking';

interface FeedbackFormProps {
  closeForm: () => void;
  sessionId: string;
  userId: string; 
}

const FeedbackForm: FC<FeedbackFormProps> = ({ closeForm, sessionId, userId  }) => {
  const [comment, setComment] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!comment.trim()) return;

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/submit_feedback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          session_id: sessionId,
          message: comment,
          email: email.trim() || null
        }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        toast.success('Feedback submitted successfully!');
        trackFeedbackSent(comment, sessionId, userId);
        closeForm();
      } else {
        toast.error('Failed to submit feedback. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="feedback-form-overlay">
      <div className="feedback-form-container">
        <h2>How can we improve? Please, let us know your thoughts...</h2>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Enter your feedback here..."
          className="feedback-textarea"
          disabled={loading}
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email (optional)"
          className="feedback-email-input"
          disabled={loading}
        />
        <button onClick={handleSubmit} className="submit-feedback-button" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        <button onClick={closeForm} className="cancel-feedback-button" disabled={loading}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default FeedbackForm;
