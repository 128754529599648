import React, { FC } from 'react';
import { marked } from 'marked';
import { Message } from './types';

const isProd = process.env.NODE_ENV === 'production';
const botAvatar = isProd ? 'https://cdn.prod.website-files.com/65cb6a0f3f7384e3bd5ed3ec/66e9cee3bca8c5fcc601b119_bot_avatar.svg' : require('../../assets/bot_avatar.svg').default;

interface MessageListProps {
  messages: Message[];
  messagesEndRef: React.RefObject<HTMLDivElement>;
}

const MessageList: FC<MessageListProps> = ({ messages, messagesEndRef }) => {
  // Function to add target="_blank" to all links
  const addTargetBlankToLinks = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const links = doc.getElementsByTagName('a');
    
    for (let i = 0; i < links.length; i++) {
      links[i].setAttribute('target', '_blank');
      links[i].setAttribute('rel', 'noopener noreferrer');
    }
    
    return doc.body.innerHTML;
  };

  return (
    <>
      {messages.map((msg, index) => (
        <div key={index} className={`message-wrapper ${msg.sender}`}>
          <div className="message-info">
            {msg.sender === 'bot' && <img src={botAvatar} alt="Bot Avatar" className="avatar" />}
            <span className="name">{msg.sender === 'bot' ? 'ComPilot' : 'You'}</span>
            <span className="timestamp">{msg.timestamp}</span>
          </div>
          <div className={`message ${msg.sender}`}>
            <div className="message-content">
              <div className="message-body">
                {msg.sender === 'bot' ? (
                  <span dangerouslySetInnerHTML={{ __html: addTargetBlankToLinks(marked(msg.text || '')) }} />
                ) : (
                  <span>{msg.text}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </>
  );
};

export default MessageList;