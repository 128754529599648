import React from 'react';
import Chatbot from './Chatbot/Chatbot';

const App: React.FC = () => (
  <div>
    <Chatbot />
  </div>
);

export default App;
