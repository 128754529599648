import React, { FC } from 'react';

interface ChatInputProps {
  input: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  sendMessage: (input: string, stage: string) => void;
  stage: string;
}

const ChatInput: FC<ChatInputProps> = ({ input, setInput, handleKeyDown, sendMessage, stage }) => {
  const getPlaceholder = () => {
    switch(stage) {
      case 'get_user_name':
        return 'Enter your name...';
      case 'get_user_email':
        return 'Enter your email...';
      default:
        return 'Type your message...';
    }
  };

  return (
    <div className="chat-input-container">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder={getPlaceholder()}
        className="custom-input"
      />
      <button className="submit-button" onClick={() => sendMessage(input, stage)}>
        <svg viewBox="0 0 24 24">
          <path d="M12 2a10 10 0 100 20 10 10 0 000-20zm1 14.5v-3H7v-2h6V7l5 4.5-5 5z"/>
        </svg>
      </button>
    </div>
  );
};

export default ChatInput;
