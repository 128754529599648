import React, { FC } from 'react';

interface OptionsProps {
  options: string[];
  handleOptionClick: (option: string) => void;
}

const Options: FC<OptionsProps> = ({ options, handleOptionClick }) => (
  <div className="options">
    {options.map((option, index) => (
      <button key={index} onClick={() => handleOptionClick(option)}>
        {option}
      </button>
    ))}
  </div>
);

export default Options;
