import { analytics } from '../index';

// Track when a user starts a conversation with the chatbot
export const trackChatbotStarted = (sessionId: string, userId: string) => {
  analytics.track('Chatbot Started', {
    session_id: sessionId,
    user_id: userId,
    timestamp: new Date().toISOString(),
  });
};

// Track when a user sends a message and receive a response
export const trackMessageSent = (message: string, llmOutput: string, sessionId: string, userId: string) => {
  analytics.track('Message Sent', {
    message,
    llm_output: llmOutput,
    session_id: sessionId,
    user_id: userId,
    timestamp: new Date().toISOString(),
  });
};

// Track when a user sends feedback
export const trackFeedbackSent = (feedback: string, sessionId: string, userId: string) => {
  analytics.track('Feedback Sent', {
    feedback,
    session_id: sessionId,
    user_id: userId,
    timestamp: new Date().toISOString(),
  });
};

// Track when the chatbot is closed
export const trackChatbotClosed = (sessionId: string, userId: string) => {
  analytics.track('Chatbot Closed', {
    session_id: sessionId,
    user_id: userId,
    timestamp: new Date().toISOString(),
  });
};

// Track the time spent in the chatbot
export const trackChatbotTimeSpent = (timeSpent: number, sessionId: string, userId: string) => {
  analytics.track('Chatbot Time Spent', {
    session_id: sessionId,
    user_id: userId,
    time_spent: timeSpent, // in seconds
    timestamp: new Date().toISOString(),
  });
};
